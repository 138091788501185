import { RevImgFull } from "../../components/common/RevImg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const HomeUsps = () => {
  return (
    <LazyLoadComponent visibleByDefault={false}>
      <section className="section">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column">
              <RevImgFull
                src="images/tablet-mobiel-screenshot-rev.png"
                alt="Screenshot van voorbeeldpagina's in REV op tablet en mobiel."
              />
            </div>

            <div className="column">
              <div className="content">
                <h2 className="subtitle is-size-3 is-size-4-touch has-text-primary has-text-weight-bold">
                  Verhoog uw productiviteit <br></br>met de Real Estate Valuator
                </h2>
              </div>
              <div className="content">
                <div className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="icon has-text-primary"
                    />
                  </span>
                  <span className="has-text-weight-medium rev-subtitle-responsive-font">
                    Actuele exploitatiekosten
                  </span>
                </div>
                <p className="block mt-1">
                  Toegang tot actuele exploitatiekosten van vastgoed, OZB
                  tarieven en rioollasten per gemeente.
                </p>
              </div>
              <div className="content">
                <div className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="icon has-text-primary"
                    />
                  </span>
                  <span className="has-text-weight-medium rev-subtitle-responsive-font">
                    Actuele kostenkengetallen
                  </span>
                </div>
                <p className="block mt-1">
                  Toegang tot actuele kostenkengetallen voor het bouwkundig en
                  installatietechnisch onderhoud van vastgoed volgens de NL-SfB
                  methode.
                </p>
              </div>
              <div className="content">
                <div className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="icon has-text-primary"
                    />
                  </span>
                  <span className="has-text-weight-medium rev-subtitle-responsive-font">
                    MJOV opstellen*
                  </span>
                </div>
                <p className="block mt-1">
                  Download een meerjaren onderhoudsverwachting voor de lange
                  termijn, tot wel 99 jaar vooruit. <br></br>
                  <em>*vanaf het Pro pakket</em>
                </p>
              </div>
              <div className="content">
                <div className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="icon has-text-primary"
                    />
                  </span>
                  <span className="has-text-weight-medium rev-subtitle-responsive-font">
                    Veilige data & back-ups
                  </span>
                </div>
                <p className="block mt-1">
                  Sla uw projecten data veilig op in onze cloud omgeving.
                </p>
              </div>
              <div className="content">
                <div className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="icon has-text-primary"
                    />
                  </span>
                  <span className="has-text-weight-medium rev-subtitle-responsive-font">
                    Professionele helpdesk
                  </span>
                </div>
                <p className="block mt-1">
                  Voor al uw bouwkundige, installatietechnische, verduurzaming
                  en taxatie vragen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LazyLoadComponent>
  );
};

export default HomeUsps;
