import * as pages from "../../navigation";
import { RevEmptyLink } from "../../components/common/Buttons";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const HomeEnergieCalculator = () => {
  return (
    <LazyLoadComponent visibleByDefault={false}>
      <section className="section has-background-success-light">
        <div className="container has-text-centered">
          <h2 className="is-size-4 has-text-primary has-text-weight-bold">
            Bereken uw energielabel
          </h2>
          <h2 className="title is-size-5 has-text-weight-bold">
            Hoeveel stappen gaat u vooruit?
          </h2>
          <div className="buttons is-centered">
            <RevEmptyLink
              className={"button is-primary"}
              path={pages.zonnepaneelcalculatie}
              text={"Zonnepanelen"}
            />
            <RevEmptyLink
              className={"button is-primary"}
              path={pages.warmtepompcalculatie}
              text={"Warmtepomp"}
            />
          </div>

          <h2 className="is-size-4 has-text-primary has-text-weight-bold">
            REV® Web App
          </h2>
          <h2 className="title is-size-5 has-text-weight-bold">
            Zet REV® Web App op uw mobiele telefoon
          </h2>
          <RevEmptyLink
            className={"button is-centered is-primary"}
            path={pages.webApp}
            text={"Ontdek het hier"}
          />
        </div>
      </section>
    </LazyLoadComponent>
  );
};

export default HomeEnergieCalculator;
