import * as pages from "./../../navigation";
import { Link } from "react-router-dom";

const HomeTitleAuthorized = () => {
  return (
    <section className="hero is-small">
      <header className="hero-body center has-background-primary-light">
        <div className="container has-text-centered p-4">
          <h2 className="title is-2 is-size-3-mobile has-text-weight-bold">
            Welkom, u bent ingelogd op REV®
          </h2>
          <h3 className="title is-5">
            Aan de slag met de Real Estate Valuator
          </h3>
          <button className="button is-primary is-info-light">
            {/* eslint-disable-next-line  */}
            <Link className={"has-text-white"} to={pages.userProjectsPage}>
              Naar projecten
            </Link>
          </button>
        </div>
      </header>
    </section>
  );
};

export default HomeTitleAuthorized;
