import * as pages from "../../navigation";
import { RevEmptyLink } from "../../components/common/Buttons";

const VastgoedTaxatiewijzer = () => {
  return (
    <>
      <section id="rev-abotabs" className="section">
        <div className="container">
          <h2 className="subtitle has-text-weight-bold has-text-primary">
            REV® en de Vastgoed Taxatiewijzers
          </h2>
          <p className="mb-5">
            Bij aanschaf van een Vastgoed Taxatiewijzer krijgt u toegang tot
            bijbehorende module in REV®. Bestel als eenmalige editie of
            abonnement.
          </p>
          <div className="columns is-multiline">
            <div className="column is-flex is-3 is-3-fullhd is-3-desktop is-6-tablet is-12-mobile">
              <div className="box is-shadowless">
                <figure className="image is-4by3 mb-4">
                  <img
                    src="/images/2025-cover-softwarebox-bel.png"
                    alt="Afbeelding cover vastgoed taxatiewijzer exploitatiekosten beleggingsvastgoed"
                  />
                </figure>
                <h3 className="content mb-1 has-text-weight-bold">
                  Beleggingsvastgoed
                </h3>
                <p className="content mb-3">
                  Bevat gegevens die nodig zijn om exploitatiekosten van
                  beleggingsvastgoed te berekenen.
                </p>
                <RevEmptyLink
                  text={"Meer informatie"}
                  path={pages.vtwBeleggingsvastgoed}
                  className={"button is-outlined"}
                />
              </div>
            </div>
            <div className="column is-flex is-3 is-3-fullhd is-3-desktop is-6-tablet is-12-mobile">
              <div className="box is-shadowless">
                <figure className="image is-4by3 mb-4">
                  <img
                    src="/images/2025-cover-softwarebox-mav.png"
                    alt="Afbeelding cover vastgoed taxatiewijzer exploitatiekosten maatschappelijk vastgoed"
                  />
                </figure>
                <h3 className="content mb-1 has-text-weight-bold">
                  Maatschappelijk Vastgoed
                </h3>
                <p className="content mb-3">
                  Bevat gegevens die nodig zijn om exploitatiekosten van
                  Maatschappelijk Vastgoed te kunnen berekenen.
                </p>
                <RevEmptyLink
                  text={"Meer informatie"}
                  path={pages.vtwMaatschappelijksvastgoed}
                  className={"button is-outlined"}
                />
              </div>
            </div>
            <div className="column is-flex is-3 is-3-fullhd is-3-desktop is-6-tablet is-12-mobile">
              <div className="box is-shadowless">
                <figure className="image is-4by3 mb-4">
                  <img
                    src="/images/2025-cover-softwarebox-bgb.png"
                    alt="Afbeelding cover vastgoed taxatiewijzer bouwgebreken"
                  />
                </figure>
                <h3 className="content mb-1 has-text-weight-bold">
                  Bouwgebreken
                </h3>
                <p className="content mb-3">
                  Bevat de meest voorkomende bouwgebreken van bouwdelen en
                  installaties met actuele herstelkosten.
                </p>
                <RevEmptyLink
                  text={"Meer informatie"}
                  path={pages.vtwBouwgebreken}
                  className={"button is-outlined"}
                />
              </div>
            </div>
            <div className="column is-flex is-3 is-3-fullhd is-3-desktop is-6-tablet is-12-mobilet">
              <div className="box is-shadowless">
                <figure className="image is-4by3 mb-4">
                  <img
                    src="/images/2025-cover-softwarebox-okk.png"
                    alt="Afbeelding cover vastgoed taxatiewijzer onderhoudskostenkompas"
                  />
                </figure>
                <h3 className="content mb-1 has-text-weight-bold">
                  Onderhoudskostenkompas
                </h3>
                <p className="content mb-3">
                  Bestaat uit o.a. een prijzenbestand van bouwkundige- en
                  installatietechnische bouwdelen, een controlelijst en meer.
                </p>
                <RevEmptyLink
                  text={"Meer informatie"}
                  path={pages.vtwOnderhoudskostenkompas}
                  className={"button is-outlined"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VastgoedTaxatiewijzer;
