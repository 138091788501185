import { RevImgFull } from "../../components/common/RevImg";
import * as links from "../../externalLinks";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { ExternRevLink } from "../../components/common/Buttons";
const HomeDetails = () => {
  return (
    <LazyLoadComponent visibleByDefault={false}>
      <section className="section has-background-primary-light">
        <div className="container">
          <div className="columns is-vcentered is-desktop">
            <div className="column has-text-centered">
              <RevImgFull
                src="/images/desktop-screenshot-refgebouwen.png"
                title="desktop scherm referentieobjecten"
                alt="overzicht desktop scherm referentiegebouw onderhoudskosten"
              />
            </div>
            <div className="column">
              <div className="content pb-2">
                <h2 className="subtitle is-size-3 is-size-4-touch has-text-weight-bold">
                  Aan de slag <br></br>met de Real Estate Valuator
                </h2>
                <div className="buttons">
                  <a
                    href={links.youtubePlaylist}
                    className="button is-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Bekijk instructievideo's
                  </a>
                  <ExternRevLink
                    className={"button is-primary is-outlined"}
                    path={links.shop}
                    target={"_blank"}
                    rel={"noopener"}
                    text={"Bestel op VAOshop"}
                  />
                </div>
              </div>
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                Zoek op gebouwtype
              </h2>
              <p>
                Zoek op gebouwtype door het invoeren van de postcode en
                huisnummer van uw vastgoedobject óf zoek en filter in het
                overzicht referentiegebouwen.
              </p>

              <br />
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                Inzicht in bouwgebreken
              </h2>
              <p>
                REV® bevat een bouwgebreken database, gesorteerd volgens de
                NL-SfB code. Krijg inzicht in de meest voorkomende bouwgebreken
                en de verwachte herstelkosten.
              </p>
              <br />
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                Kennisbank aan informatie
              </h2>
              <p>
                De kennisbank bevat informatie over o.a. actuele OZB tarieven en
                rioollasten, opstalverzekeringen en herbouwwaarden, toelichting
                op verduurzamen en meer.
              </p>
              <br />
              <h2 className="subtitle is-size-5-mobile is-4 mb-1 has-text-weight-medium">
                API koppeling kengetallen
              </h2>
              <p>
                Door middel van een API is het mogelijk om de kengetallen uit
                REV® rechtstreeks in te lezen in uw taxatie- of
                calculatiesoftware.
              </p>
            </div>
          </div>
        </div>
      </section>
    </LazyLoadComponent>
  );
};

export default HomeDetails;
