import * as pages from "./../../navigation";

const HomeTitle = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column rev-header-column-left">
            <figure className="image is-128x128 rev-rectangle-image">
              <img src="../images/outline-rectangle.png" />
            </figure>
            <h1 className="title is-1 is-size-3-touch has-text-weight-bold">
              Bereken <br />
              exploitatiekosten <br />
              van uw vastgoed
            </h1>
            <h2 className="is-size-5 is-size-6-mobile has-text-weight-bold has-text-warning">
              Vastgoedkennis in de pocket met de Real Estate Valuator
            </h2>
            <div className="content is-size-5 is-size-6-mobile">
              <p>
                Bereken exploitatiekosten en verduurzamingskosten. Door middel
                van referentieobjecten kunt u de kosten berekenen voor het
                onderhoud, het herstellen van gebreken en
                duurzaamheidsmaatregelen.
              </p>
              <p className="has-text-weight-medium is-italic">
                Erkende bron voor Flux taxatiesoftware, TMI gebruikers en DuPa.
              </p>
              <p>
                <span className="has-text-weight-bold">Ideaal voor: </span>
                <br></br>
                Taxateurs, makelaars, woningcorporaties, VvE’s, Overheid en
                bouwkundig en/of installatietechnisch adviseurs.
              </p>
            </div>
            <div className="buttons">
              <a className="button is-primary" href="#rev-abotabs">
                Bekijk abonnementen
              </a>
              <a
                className="button is-primary is-light"
                href={pages.realEstateValuator}
              >
                Ontdek meer
              </a>
              <a
                className="button is-outlined has-text-grey-dark"
                href={pages.handigeLinks}
              >
                Handige links
              </a>
            </div>
          </div>
          <div className="column">
            <figure className="image">
              <img
                src="../images/rev-box-header.png"
                class="rev-softwarebox"
                title="REV Softwarebox"
                alt="Voorbeeld van een softwarebox"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeTitle;
