import HomeTitle from "./title";
import HomeUsps from "./usps";
import HomeEnergieCalculator from "./energieCalculator";
import HomeReview from "./review";
import HomeDetails from "./details";
import HomeEnvironment from "./environment";
import { selectIsAuthorized } from "../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import HomeTitleAuthorized from "./titleAuthorized";
import VastgoedTaxatiewijzer from "./vastgoedTaxatiewijzer";
import ReleasNotes from "./releaseNotes";
function Home(props) {
  const isAuthorized = useSelector(selectIsAuthorized);

  return (
    <>
      <HomeEnvironment />
      <ReleasNotes />
      {isAuthorized && <HomeTitleAuthorized />}
      {!isAuthorized && <HomeTitle />}
      {!isAuthorized && <HomeEnergieCalculator />}
      <HomeUsps />
      <HomeReview />
      <HomeDetails />
      <VastgoedTaxatiewijzer />
    </>
  );
}

export default Home;
